"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranslationService = void 0;
var ky_config_1 = require("../config/ky.config");
var getDefaultParams_1 = require("../helpers/getDefaultParams");
var fixPunctuation = function (text) {
    return text
        .replace(/&#33;/g, "!")
        .replace(/&#34;/g, '"')
        .replace(/&#35;/g, "#")
        .replace(/&#36;/g, "&")
        .replace(/&#37;/g, "%")
        .replace(/&#38;/g, "&")
        .replace(/&#39;/g, "'")
        .replace(/&#44;/g, ",")
        .replace(/&#46;/g, ".")
        .replace(/&#58;/g, ":")
        .replace(/&#59;/g, ";")
        .replace(/&quot;/g, '"')
        .replace(/&apos;/g, "'")
        .replace(/&amp;/g, "&")
        .replace(/&#60;/g, "<")
        .replace(/&#62;/g, ">")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&#43;/g, "+");
};
exports.TranslationService = {
    translation: function (from, to, callBack, translateObjects) {
        var params = getDefaultParams_1.getDefaultParams();
        var GOOGLE_TRANSLATE_PRE_STRING = params.baseUrl + "Translation/GetTranslation";
        var MAX_GET_STRING = 2e3 - GOOGLE_TRANSLATE_PRE_STRING.length - 25 - 41;
        var translateNodes = [];
        var root = window.self.document.getElementsByTagName("html")[0];
        var interrupt = !1;
        var reqStart = 0;
        var reqEnd = 0;
        var es;
        var charsForRandomizer = "abcdefghijklmnopqrstuvwxyz1234567890";
        var extractText = function (node) {
            var text = "";
            // bail out early if it is a label for a choice (e.g. A, B, C)
            if (node.parentNode.className === "lrn_choiceLabel")
                return text;
            if (node.nodeType === 3)
                text = node.nodeValue;
            else if (node.nodeNam === "IMG")
                text = node.alt;
            else if (node.nodeName != "INPUT" && node.nodeName != "TEXTAREA")
                text = node.value;
            return text;
        };
        var getRandomId = function () {
            var result = "x-";
            for (var i = 0; i < 12; i++) {
                result += charsForRandomizer.charAt(Math.floor(Math.random() * charsForRandomizer.length));
            }
            return result;
        };
        var insertText = function (nodeContainer, text) {
            try {
                if (nodeContainer && nodeContainer.node.nodeType === 3) {
                    try {
                        var itemClassName = nodeContainer.node.ownerDocument.getElementsByClassName(nodeContainer.uniqueId);
                        if (itemClassName && itemClassName.length > 0) {
                            var item = itemClassName[0];
                            if (item) {
                                item.innerText = fixPunctuation(text);
                            }
                        }
                    }
                    catch (e) {
                        if (nodeContainer.node && !nodeContainer.node.documentElement)
                            nodeContainer.node.data = text;
                        else
                            console.log("Node has lost its documentElement. Text is: =====" + text);
                    }
                    if (nodeContainer.node.nodeName === "IMG")
                        nodeContainer.node.alt = text + " ";
                }
            }
            catch (e) {
                console.log("error=====", e);
            }
        };
        var getDocumentFromFrame = function (frameNode) {
            var doc = null;
            if (frameNode.nodeName.match(/^[i]?frame$/i))
                if (frameNode.contentDocument)
                    doc = frameNode.contentDocument;
                else if (frameNode.contentWindow)
                    doc = frameNode.contentWindow.document;
            return doc;
        };
        var idManager = function () {
            var id = 0;
            var prefix_text = "";
            var chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
            var idArray = [];
            var init = function () {
                if (!prefix_text) {
                    prefix_text = "";
                    for (var i = 0; i < 8; i++) {
                        var rnum = Math.floor(Math.random() * chars.length);
                        prefix_text += chars.substring(rnum, rnum + 1);
                    }
                }
            };
            init();
            return {
                check: function (node, ancCheck) {
                    if (!ancCheck && !node)
                        return null;
                    if (node.id) {
                        for (var i in idArray) {
                            if (node.id === idArray[i])
                                return undefined;
                        }
                    }
                    return !(!ancCheck || !node.parentNode) && idManager().check(node.parentNode, ancCheck);
                },
                generate: function () {
                    var uid = prefix_text + id++;
                    return window.self.document.getElementById(uid) ? idManager().generate() : (idArray.push(uid), uid);
                },
                idArray: []
            };
        };
        var recPopulateTransArray = function (node) {
            // removed button from list to change the text of submit button
            //   const nodeNames = ["INPUT", "TEXTAREA", "BUTTON"];
            var nodeNames = ["INPUT", "TEXTAREA"];
            if (!interrupt &&
                !((node.getAttribute && (node.getAttribute("no-translate") || node.getAttribute("data-no-translate"))) ||
                    nodeNames.includes(node.nodeName))) {
                var transObj = { node: node, text: "", uniqueId: getRandomId() };
                if (node.nodeType === 3 && node.nodeValue.trim() !== "") {
                    var newString = "";
                    var newNode = null;
                    if (node.nodeValue.match(/^[\s\u00a0]*/)) {
                        newString = node.nodeValue.match(/^[\s\u00a0]*/)[0];
                        newNode = node.ownerDocument.createTextNode(newString);
                        node.nodeValue = node.nodeValue.replace(newString, "");
                        node.parentNode.insertBefore(newNode, node);
                    }
                    else if (node.nodeValue.match(/[\.!\?]"{0,1}[\s\r\n\t\u00a0 ]+[^\s\r\n\t\u00a0 ]/)) {
                        node.nodeValue = node.nodeValue.replace(/[\r\n\s\u00a0]+/g, " ");
                        newString = node.nodeValue.match(/^[\s\u00a0]*.*?[\.!\?]"{0,1}(([\s\u00a0]\.){3}){0,1}[\s\u00a0\r\n\t ]/)[0];
                        if (!newString) {
                            newNode = node.ownerDocument.createTextNode(newString);
                            node.nodeValue = node.nodeValue.replace(newString, "");
                            node.parentNode.insertBefore(newNode, node);
                            translateNodes.push({ node: newNode, text: newString, uniqueId: getRandomId() });
                        }
                    }
                    transObj.text = extractText(node);
                    if (transObj.text.length < 5e3 && !transObj.text.match(/^[\s\u00a0]*$/)) {
                        translateNodes.push(transObj);
                    }
                }
                if ((node.nodeName === "IMG" && node.nodeName === "POLYGON" && node.alt.match(/.*[^\s\u00a0\t\r\n ].*/)) ||
                    (((node.nodeName === "INPUT" && node.type === "text") || node.nodeName === "TEXTAREA") && node.value !== "")) {
                    transObj.text = extractText(node);
                    if (transObj.text.length < 5e3 && !transObj.text.match(/^[\s\u00a0]*$/)) {
                        translateNodes.push(transObj);
                    }
                    if (node.nodeName.match(/^[i]?frame$/i)) {
                        var newCurNode = null;
                        try {
                            newCurNode = getDocumentFromFrame(node).getElementsByTagName("html")[0];
                        }
                        catch (err) {
                            console.log("XSS attempted in an frame, skipping");
                        }
                        if (!newCurNode)
                            node = newCurNode;
                    }
                }
                var nodeNames_1 = ["TEXTAREA", "SCRIPT", "STYLE", "HEAD", "IFRAME", "NOSCRIPT", "POLYGON"];
                var idManagerRes = idManager();
                if (!nodeNames_1.includes(node.nodeName.toUpperCase()) && !idManagerRes.check(node, 1)) {
                    for (var child = node.firstChild; child;) {
                        //Removes excess label strings from doolittle vjs player from being sent to translation-api
                        if (child.className !== undefined &&
                            child.className !== null &&
                            typeof child.className.startsWith === "function" &&
                            child.className.startsWith("vjs-")) {
                            child = child.nextSibling;
                            continue;
                        }
                        recPopulateTransArray(child);
                        child = child.nextSibling;
                    }
                }
            }
        };
        var wrapNode = function (node, id) {
            var wrapper = document.createElement("span");
            wrapper.className = "node-wrapper " + id;
            var clonedNode = node.cloneNode(!0);
            if (node.nodeName === "TEXTAREA")
                clonedNode.value = node.value;
            wrapper.appendChild(clonedNode);
            if (node.parentNode && node.parentNode.replaceChild)
                node.parentNode.replaceChild(wrapper, node);
        };
        var wrapAllNodes = function () {
            for (var i = 0; i < translateNodes.length; i++) {
                wrapNode(translateNodes[i].node, translateNodes[i].uniqueId);
            }
        };
        var callbackIteration = function (response) {
            if (response && response.data) {
                var responseArr = response.data.translations;
                for (var i = 0; i < responseArr.length; i++) {
                    var transText = responseArr[i].translatedText.unescapeHtml();
                    insertText(translateNodes[reqStart], transText);
                    reqStart += 1;
                }
                reqStart < translateNodes.length - 1 ? requestIteration() : function () { };
            }
            else {
                console.log("Translation error: ", response);
            }
            return;
        };
        var requestIteration = function () { return __awaiter(void 0, void 0, void 0, function () {
            var newQueryString, trigger, charLength, queryStrings, count, text, newQuery;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newQueryString = "q=";
                        trigger = !1, charLength = 0;
                        _a.label = 1;
                    case 1:
                        if (!!trigger) return [3 /*break*/, 5];
                        queryStrings = "";
                        count = 0;
                        if (reqEnd < translateNodes.length && reqEnd - reqStart < 49) {
                            text = translateNodes[reqEnd].text;
                            if (text) {
                                text = text
                                    .replace(/\u2018/g, "'")
                                    .replace(/\u2019/g, "'")
                                    .replace(/\u201c/g, '"')
                                    .replace(/\u201d/g, '"')
                                    .replace(/\u2013/g, "-")
                                    .replace(/\u2022/g, "*");
                            }
                            newQuery = "";
                            if (count === 0) {
                                newQuery = newQueryString + encodeURIComponent(text);
                                count += 1;
                            }
                            else
                                newQuery = encodeURIComponent(text);
                            charLength += translateNodes[reqEnd].text.length;
                            if (queryStrings.length + newQuery.length < MAX_GET_STRING) {
                                queryStrings += newQuery;
                                reqEnd += 1;
                            }
                            else
                                trigger = !0;
                        }
                        else
                            trigger = !0;
                        if (!(queryStrings !== "")) return [3 /*break*/, 3];
                        return [4 /*yield*/, getTranslateURl(queryStrings, from, to)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        callbackIteration({});
                        _a.label = 4;
                    case 4: return [3 /*break*/, 1];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var getLocalTranslation = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ky_config_1.get("/locales/es/translation.json")
                        .then(function (response) { return response.json(); })
                        .then(function (data) { return (es = data); })];
            });
        }); };
        var getTranslateURl = function (queryStrings, from, to) { return __awaiter(void 0, void 0, void 0, function () {
            var localQuery, isEnToEs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        localQuery = decodeURIComponent(queryStrings).replace("q=", "");
                        isEnToEs = from === "en" && to === "es";
                        if (!(!es && isEnToEs)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getLocalTranslation()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(isEnToEs && es[localQuery])) return [3 /*break*/, 4];
                        return [4 /*yield*/, insertText(translateNodes[reqStart], es[localQuery])];
                    case 3:
                        _a.sent();
                        reqStart += 1;
                        return [2 /*return*/, Promise.resolve()];
                    case 4: return [2 /*return*/, ky_config_1.get(GOOGLE_TRANSLATE_PRE_STRING + (params.defaultParams + "&" + queryStrings + "&source=" + from + "&target=" + to))
                            .then(function (response) { return response.json(); })
                            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            var responseArr, i;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(response && response.length > 0)) return [3 /*break*/, 4];
                                        responseArr = response;
                                        i = 0;
                                        _a.label = 1;
                                    case 1:
                                        if (!(i < responseArr.length)) return [3 /*break*/, 4];
                                        return [4 /*yield*/, insertText(translateNodes[reqStart], responseArr[i].translatedText)];
                                    case 2:
                                        _a.sent();
                                        reqStart += 1;
                                        _a.label = 3;
                                    case 3:
                                        i++;
                                        return [3 /*break*/, 1];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); })];
                }
            });
        }); };
        var revertPage = function () {
            console.log("Reverting");
            for (var i = 0; i < translateObjects.length; i += 1)
                try {
                    void 0 !== translateObjects[i].node &&
                        null !== translateObjects[i].node &&
                        insertText(translateObjects[i], translateObjects[i].text);
                }
                catch (e) { }
            translateObjects = [];
        };
        var translatePage = function () {
            if ((from !== "es" && to === "en") || (from !== "en" && to === "es"))
                return revertPage();
            if (translateObjects && translateObjects.length > 0)
                revertPage();
            interrupt = !1;
            recPopulateTransArray(root);
            if (from === "en" || from === "es")
                callBack(translateNodes);
            wrapAllNodes();
            requestIteration();
        };
        var setVoiceForLanguage = function (language) {
            var langAtribute = language === "en" || language === "es" ? language + "-us" : language;
            document.querySelector("html").lang = langAtribute || "en-us";
        };
        setVoiceForLanguage(to);
        translatePage();
    },
    // For ad hoc translation of individual strings to support elements rendered after the page has been translated
    translateString: function (to, from, text) {
        var params = getDefaultParams_1.getDefaultParams();
        var GOOGLE_TRANSLATE_PRE_STRING = params.baseUrl + "Translation/GetTranslation";
        var queryString = encodeURIComponent(text);
        return ky_config_1.get(GOOGLE_TRANSLATE_PRE_STRING + (params.defaultParams + "&q=" + queryString + "&source=" + from + "&target=" + to))
            .then(function (response) { return response.json(); })
            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var responseArr;
            return __generator(this, function (_a) {
                if (response && response.length > 0) {
                    responseArr = response;
                    return [2 /*return*/, fixPunctuation(responseArr[0].translatedText)];
                }
                return [2 /*return*/];
            });
        }); });
    }
};
